import React, { Component, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import _ from 'services/i18n';
import { standardDateTimeFormats } from 'common/datetime/convertToDate';

interface DateInputComponentProps {
    fieldId?: string;
    value: string;
    onChange: (e: { target: {name: string; value: string} }) => void;
    name?: string;
    dateUnknown?: boolean;
    timeApprox?: boolean;
    showTime?: boolean;
    dateFormat?: string;
    displayDateFormat?: string;
    minDate?: string;
    maxDate?: string;
    disabled?: boolean;
    onSetInvalid?: () => void;
    onBlur?: () => void;
    onToggleApprox?: (timeIsApproximate: boolean) => void;
    onToggleUnknown?: (dateIsUnknown: boolean) => void;
}
interface DateInputComponentState {
    value: Moment;
    validDate: boolean;
    minutes: string;
    hours: string;
    hoursFormatting?: boolean;
    minutesFormatting?: boolean;
}
type EventHandlers = Record<string, (e: Event) => void>;

export default class DateInput extends Component<DateInputComponentProps, DateInputComponentState> {
    static defaultProps = {
        dateFormat: standardDateTimeFormats.date_input,
        displayDateFormat: standardDateTimeFormats.nhs_date_short,
    };
    events: EventHandlers;
    constructor (props) {
        super(props);

        if (this.props.showTime) {
            let timeValue = moment(this.props.value, `${this.props.dateFormat} HH:mm`, true);
            if(!timeValue.isValid()) {
                timeValue = moment(this.props.value, this.props.dateFormat, true);
            }
            this.state = {
                value: this.props.value ? timeValue : void 0,
                hours:  this.props.value ? pad(timeValue.hours()) : '00',
                minutes:  this.props.value ? pad(timeValue.minutes()) : '00',
                validDate: true,
                hoursFormatting: true,
                minutesFormatting: true,
            };
        } else {
            this.state = {
                value: this.props.value ? moment(this.props.value, this.props.dateFormat,true) : null,
                hours: '00',
                minutes: '00',
                validDate: true,
            };
        }

        this.events = {};

        this.events.onChange = this.onChange.bind(this);
        this.events.onChangeHours = this.onChangeHours.bind(this);
        this.events.onFocusHours = this.onFocusHours.bind(this);
        this.events.onBlurHours = this.onBlurHours.bind(this);
        this.events.onFocusMinutes = this.onFocusMinutes.bind(this);
        this.events.onBlurMinutes = this.onBlurMinutes.bind(this);
        this.events.onChangeMinutes = this.onChangeMinutes.bind(this);
        this.events.onSetInvalid = this.onSetInvalid.bind(this);
        this.events.onSpinnerUp = this.onSpinnerUp.bind(this);
        this.events.onSpinnerDown = this.onSpinnerDown.bind(this);
        this.events.onKeyPress = this.onKeyPress.bind(this);
        this.events.onClickUnknown = this.onClickUnknown.bind(this);
        this.events.onClickApprox = this.onClickApprox.bind(this);
        this.loadComponent = this.loadComponent.bind(this);
        this.updateTime = this.updateTime.bind(this);
    }

    componentDidUpdate(prevProps: DateInputComponentProps) {
        if (this.props.value !== prevProps.value) {
            let date = moment(this.props.value, this.props.dateFormat, true);
            if(!date.isValid()) {
                date = moment(this.props.value, `${this.props.dateFormat} HH:mm`, true);
            }
            this.setState((state) => ({
                ...state,
                value: this.props.value ? date : void 0,
                validDate: true,
            }));
        }

        if(this.props.dateUnknown !== prevProps.dateUnknown || this.props.timeApprox !== prevProps.timeApprox) {
            this.updateTime();
        }

        if(this.props.showTime !== prevProps.showTime) {
            this.props.onChange?.({
                target: {
                    name: this.props.name || '',
                    value: this.props.showTime ? this.state.value.format(`${this.props.dateFormat} HH:mm`) : this.state.value.format(this.props.dateFormat)
                }
            });
            if(!this.props.showTime) {
                this.props.onToggleApprox(false);
                this.props.onToggleUnknown(false);
            }
        }

    }

    onClickApprox (e) {
        this.props.onToggleApprox?.(e.target.checked);
    }

    onClickUnknown (e) {
        this.props.onToggleUnknown?.(e.target.checked);
        this.props.onToggleApprox?.(false);
    }

    onKeyPress (e) {
        if (e.keyCode == 38) {
            this.events.onSpinnerUp(e);
        }
        if (e.keyCode == 40) {
            this.events.onSpinnerDown(e);
        }
    }

    UNSAFE_componentWillMount () {
        this.loadComponent();
    }

    onSpinnerUp (e) {
        let newMinutes = parseInt(this.state.minutes as string), newHours = parseInt(this.state.hours);
        // work out which box is focused

        if (isNaN(newMinutes)) {
            newMinutes = 0;
        }
        if (isNaN(newHours)) {
            newHours = 0;
        }

        if (this.state.hoursFormatting) {
            newMinutes++;

            if (newMinutes < 0) {
                newMinutes = 0;
            }

            if (newHours < 0) {
                newHours = 0;
            }

            if (newMinutes > 59) {
                newMinutes = 0;
                newHours++;

                if (newHours>23) {
                    newHours = 0;
                }
            }

            this.setState({
                minutes: pad(newMinutes),
                hours: pad(newHours)
            }, this.updateTime);
        } else {
            newHours++;

            if (newHours < 0) {
                newHours = 0;
            }

            if (newHours>23) {
                newHours = 0;
            }

            this.setState({
                hours: pad(newHours)
            }, this.updateTime);
        }

        e.preventDefault();
        e.stopPropagation();
    }

    onSpinnerDown (e) {
        let newMinutes = parseInt(this.state.minutes), newHours = parseInt(this.state.hours);

        if (isNaN(newMinutes)) {
            newMinutes = 0;
        }
        if (isNaN(newHours)) {
            newHours = 0;
        }

        // work out which box is focused
        if (this.state.hoursFormatting) {
            newMinutes--;

            if (newMinutes > 59) {
                newMinutes = 59;
            }

            if (newHours > 23) {
                newHours = 23;
            }

            if (newHours < 0) {
                newHours = 23;
            }

            if (newMinutes < 0) {
                newMinutes = 59;
                newHours--;

                if (newHours < 0) {
                    newHours = 23;
                }
            }

            this.setState({
                minutes: pad(newMinutes),
                hours: pad(newHours)
            }, this.updateTime);
        } else {
            newHours--;

            if (newHours > 23) {
                newHours = 23;
            }

            if (newHours < 0) {
                newHours = 23;
            }

            this.setState({
                hours: pad(newHours)
            }, this.updateTime);
        }

        e.preventDefault();
        e.stopPropagation();
    }

    loadComponent () {
        // format the hours and minute stored values correctly
        if (this.props.showTime) {
            this.onBlurHours();
            this.onBlurMinutes();
        }
    }

    UNSAFE_componentWillReceiveProps (newProps: DateInputComponentProps) {

        if (newProps.showTime) {
            let newValue = moment(newProps.value || '', `${this.props.dateFormat} HH:mm`, true);
            if(!newValue.isValid()) {
                newValue = moment(newProps.value, this.props.dateFormat, true);
            }
            // Do nothing if the date and time haven't changed.
            if (newProps.value === this.props.value) {
                return;
            }

            this.setState( {
                value: newValue,
                hours: pad(newValue.hours()),
                minutes: pad(newValue.minutes()),
            }, this.updateTime);
        } else {
            const newValue = moment(newProps.value || '', this.props.dateFormat, true);
            // Do nothing if the date hasn't changed.
            if (newProps.value === this.props.value) {
                return;
            }

            this.setState({
                value: newValue,
            });
        }
    }

    onFocusHours (e) {
        const paddedHours = pad(this.state.hours);

        e.target.select();
        this.setState({
            hours: paddedHours,
            hoursFormatting: false
        });
    }

    onBlurHours () {
        const paddedHours = pad(this.state.hours);

        this.setState({
            hours: paddedHours,
            hoursFormatting: true
        }, this.updateTime);
    }

    onFocusMinutes (e) {
        const paddedMinutes = pad(this.state.minutes);

        e.target.select();
        this.setState({
            minutes: paddedMinutes,
            minutesFormatting: false
        });
    }

    onBlurMinutes () {
        const paddedMinutes = pad(this.state.minutes);

        this.setState({
            minutes: paddedMinutes,
            minutesFormatting: true
        }, this.updateTime);
    }

    onChange (data: Moment) {
        this.setState({
            value: data,
            validDate: true
        }, () => {
            if (this.props.showTime) {
                this.updateTime();
            }


            this.props.onChange?.({
                target: {
                    name: this.props.name || '',
                    value: this.props.showTime ? this.state.value.format(`${this.props.dateFormat} HH:mm`) : this.state.value.format(this.props.dateFormat)
                }
            });


        });
    }

    onChangeHours (e) {
        if (this.props.showTime) {
            this.setState({
                hours: e.target.value
            });
        }
    }

    onChangeMinutes (e) {
        if (this.props.showTime) {
            this.setState({
                minutes: e.target.value
            });
        }
    }

    onSetInvalid () {
        this.setState({
            validDate: false
        });

        this.props.onSetInvalid?.();
    }

    updateTime () {
        const dateMoment = this.state.value?.clone(),
            hours = parseInt(this.state.hours),
            minutes = parseInt(this.state.minutes);

        if (!this.props.showTime) {
            return;
        }

        if (!this.props.onChange) {
            return;
        }

        if (isNaN(hours) || isNaN(minutes)) {
            this.props.onSetInvalid?.();
            return;
        }

        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 || !this.state.validDate) {
            this.props.onSetInvalid?.();
            return;
        }

        dateMoment.hours(hours);
        dateMoment.minutes(minutes);

        this.props.onChange({
            target: {
                name: this.props.name || '',
                value: this.props.showTime ? dateMoment.format(`${this.props.dateFormat} HH:mm`) : dateMoment.format(this.props.dateFormat)
            }
        });
    }

    render () {
        return (
            <CustomDatePicker
                {...this.props}
                minDate={moment(this.props.minDate, this.props.dateFormat)}
                maxDate={moment(this.props.maxDate, this.props.dateFormat)}
                events={this.events}
                state={this.state}
            />
        );
    }
}
/**
 * Used in DateInput
 */
export const CustomDatePicker = (props) => {

    const {
        fieldId,
        state,
        events,
        showTime,
        maxDate,
        minDate,
        disabled,
        onBlur,
    } = props;

    const timeProps: TimeProps = {
        showTime: showTime,
        hours: state.hours,
        minutes: state.minutes,
        hoursFormatting: state.hoursFormatting,
        minutesFormatting: state.minutesFormatting,
        onChangeHours: events.onChangeHours,
        onChangeMinutes: events.onChangeMinutes,
        onFocusHours: events.onFocusHours,
        onFocusMinutes: events.onFocusMinutes,
        onBlurHours: events.onBlurHours,
        onBlurMinutes: events.onBlurMinutes,
        onSpinnerUp: events.onSpinnerUp,
        onSpinnerDown: events.onSpinnerDown,
        onKeyPress: events.onKeyPress
    };

    const min = useMemo(() => {
        return minDate?.isValid?.() ? minDate.toDate() : void 0;
    }, [minDate]);

    const max = useMemo(() => {
        return maxDate?.isValid?.() ? maxDate.toDate() : void 0;
    }, []);

    const handleChange = useCallback((date: Date) => {
        return events.onChange(moment(date).local());
    }, []);

    return (
        <DatePicker
            customInput={(
                <CustomDateInput
                    fieldId={fieldId}
                    onSetInvalid={events.onSetInvalid}
                    timeProps={timeProps}
                    timeApprox={props.timeApprox}
                    onClickApprox={events.onClickApprox}
                    dateUnknown={props.dateUnknown}
                    onClickUnknown={events.onClickUnknown}
                    onBlur={onBlur}
                    displayDateFormat={props.displayDateFormat}
                // value={<parameter will be passed by datePicker, in format like MM/DD/YYYY>}
                />
            )}
            selected={state.value?.toDate()}
            onChange={handleChange}
            minDate={min}
            maxDate={max}
            disabled={disabled}
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            peekNextMonth
        />
    );
};

export interface TimeProps {
    showTime: boolean;
    hours: string;
    minutes: string;
    hoursFormatting: boolean;
    minutesFormatting: boolean;
    onChangeHours: () => void;
    onChangeMinutes: () => void;
    onFocusHours: () => void;
    onFocusMinutes: () => void;
    onBlurHours: () => void;
    onBlurMinutes:  () => void;
    onSpinnerUp: () => void;
    onSpinnerDown: () => void;
    onKeyPress: () => void;
}
interface CustomDateInputProps {
    timeProps: TimeProps;
    timeApprox: boolean;
    dateUnknown: boolean;
    onClickUnknown: () => void;
    onClickApprox: () => void;
    displayDateFormat?: string;
    fieldId?: string;
    value?: string;
    name?: string;
    disabled?: boolean;
    onSetInvalid?: () => void;
    onChange?: ({ target: { value: Moment } }) => void;
    onClick?: () => void;
    onBlur?: () => void;
}
interface CustomDateInputState {
    value: string;
    storedValue: string;
    hours: string;
    minutes: string;
}
const DATE_FORMAT_FROM_DATE_PICKER = 'MM/DD/YYYY';
/**
 * Used in CustomDatePicker (above)
 */
class CustomDateInput extends Component<CustomDateInputProps, CustomDateInputState> {
    constructor(props: CustomDateInputProps) {
        super(props);
        const momentDate = moment(props.value, DATE_FORMAT_FROM_DATE_PICKER);
        let formattedDate;
        if (momentDate.isValid()) {
            formattedDate = momentDate.format(props.displayDateFormat);
        } else {
            formattedDate = '';
        }

        this.state = {
            value: formattedDate,
            storedValue:  formattedDate,
            hours: props.timeProps.hours,
            minutes: props.timeProps.minutes
        };

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const momentDate = moment(newProps.value, DATE_FORMAT_FROM_DATE_PICKER);
        let formattedDate;
        if (momentDate.isValid()) {
            formattedDate = momentDate.format(this.props.displayDateFormat);
        } else {
            formattedDate = '';
        }

        // Only update state if necessary.
        if (formattedDate != this.state.value || formattedDate != this.state.storedValue) {
            this.setState({
                value: formattedDate,
                storedValue: formattedDate
            });
        }
    }

    onBlur() {
        this.props.onBlur?.();
        if(this.dateHasValidFormat(this.state.value)) {
            return;
        }
        this.props.onSetInvalid?.();
    }

    dateHasValidFormat = (dateString: string): boolean => {
        const formats = [
            'YYYY-MM-DD',
            'YYYY/MM/DD',
            'YYYY MM DD',
            'DD-MMM-YYYY',
            'DD/MMM/YYYY',
            'DD MMM YYYY',
            'DD-MMM-YY',
            'DD/MMM/YY',
            'DD MMM YY',
            'DD-MMMM-YYYY',
            'DD/MMMM/YYYY',
            'DD MMMM YYYY',
            'DD-MMMM-YY',
            'DD/MMMM/YY',
            'DD MMMM YY',
            'DD-MM-YYYY',
            'DD/MM/YYYY',
            'DD MM YYYY',
            'DD-MM-YY',
            'DD/MM/YY',
            'DD MM YY'
        ];

        return formats.some((item) => {
            const check = moment(dateString, item, true);

            if (check.isValid()) {
                return this.setMoment(check);
            }
        });
    }

    setMoment(momentValue: Moment) {
        const newValue = momentValue.format(this.props.displayDateFormat);

        this.setState({
            storedValue: newValue,
            value: newValue
        });

        this.props.onChange({
            target: {
                value: momentValue
            }
        });

        return true;
    }

    onChange(e) {
        const newValue = e.target.value;

        this.setState({
            value: newValue
        });
    }

    render() {
        return (
            <div className="date-widget">
                <input
                    id={this.props.fieldId}
                    onChange={this.onChange}
                    disabled={this.props.disabled || this.props.dateUnknown}
                    name={this.props.name}
                    onClick={this.props.onClick}
                    onBlur={this.onBlur}
                    value={this.state.value}
                    type="text"
                    className="form-control date-custom-text"
                    placeholder={_`Choose date...`}
                />
                <Time {...this.props.timeProps} disabled={this.props.dateUnknown || this.props.disabled} />
                {
                    this.props.timeProps.showTime && (
                        <>
                            <label className="checkbox-label" data-disabled={this.props.disabled} title={_`Time is approximate`}>
                                <input
                                    type="checkbox"
                                    disabled={this.props.dateUnknown || this.props.disabled}
                                    checked={this.props.timeApprox}
                                    onChange={this.props.onClickApprox} />
                                &nbsp;
                                {_`Approx`}
                            </label>
                            <label className="checkbox-label" data-disabled={this.props.disabled} title={_`Date is unknown`}>
                                <input
                                    type="checkbox"
                                    disabled={this.props.disabled}
                                    checked={this.props.dateUnknown}
                                    onChange={this.props.onClickUnknown} />
                                &nbsp;
                                {_`Unknown`}
                            </label>
                        </>
                    )}
            </div>
        );
    }
}

/**
 *  Component is used in CustomDateInput
 */
const Time = (props) => {
    let paddedHours = props.hours, paddedMinutes = props.minutes;
    if (!props.showTime) {
        return <noscript />;
    }


    if (props.hoursFormatting) {
        paddedHours = pad(paddedHours);
    }
    if (props.minutesFormatting) {
        paddedMinutes = pad(paddedMinutes);
    }

    return (
        <span className="nhs-time-widget" title="Enter a time using the 24-hour clock, e.g. 13:05">
            <input type="text" disabled={props.disabled} className="hour-column" value={paddedHours} onKeyDown={props.onKeyPress} onChange={props.onChangeHours} onFocus={props.onFocusHours} onBlur={props.onBlurHours} />
            <span className="separator">:</span>
            <input type="text" disabled={props.disabled} className="minute-column" value={paddedMinutes} onKeyDown={props.onKeyPress} onChange={props.onChangeMinutes} onFocus={props.onFocusMinutes}  onBlur={props.onBlurMinutes} />
            <span className="spinner-up" onMouseDown={!props.disabled ? props.onSpinnerUp : undefined}/>
            <span className="spinner-down" onMouseDown={!props.disabled ? props.onSpinnerDown : undefined}/>
        </span>
    );
};

function pad(number) {
    const realNumber = parseInt(number);

    if (isNaN(realNumber)) {
        return number;
    }

    if (realNumber < 10) {
        return '0' + realNumber;
    }

    return realNumber;
}
