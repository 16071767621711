import React, { useState, useCallback, useEffect } from 'react';
import { useAPI } from 'common/useAPI';

const mapUuid = (result: any) => result.uuid;

export function useCompositionCollection({
    archetype,
    query = null,
    folderId = undefined,
    teamId = undefined,
    getListFunction = undefined,
    limitFetching = undefined,
}: {
    archetype: string;
    query?: string[];
    folderId?: number;
    teamId?: number;
    getListFunction?: ({ teamId, page, perPage }: {teamId: number; page?: number; perPage?: number}) => Promise<any[]>;
    limitFetching?: boolean;
}): [
    any[],
    {
        isFetching: boolean;
        isLazyFetching: boolean;
        isError: boolean;
        isInvalidated: boolean;
        totalItems: number;
    },
    {
        invalidate: () => void;
    }
] {
    const [list, setList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isLazyFetching, setIsLazyFetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isInvalidated, setIsInvalidated] = useState(false);
    const [isNeedToFetch, setIsNeedToFetch] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const perPage = 10;
    const { fullList } = useAPI();

    const fetchList = useCallback(
        async (page) => {
            !limitFetching && setIsFetching(true);
            try {
                if (getListFunction) {
                    const [list, total] = await getListFunction({ teamId, page, perPage });
                    setIsError(false);
                    return [list, total];
                } else {
                    //This is still wrong and bad because we'll retrieve the full composition list for each 'page'
                    // but at least we won't get in infinite loops of server calls any more.
                    const response = await fullList(archetype, query, folderId, teamId);
                    const pageOffset = (page-1) * perPage;
                    const results = response.message.results;
                    const totalResults = results.length;
                    const resultsPage = pageOffset >= totalResults
                        ? []
                        : results.slice(pageOffset, Math.min(pageOffset + perPage, totalResults));
                    const list = resultsPage.map(mapUuid);
                    setIsLazyFetching(false);
                    setIsError(false);
                    return [list, totalResults];
                }
            } catch (err) {
                console.error(err);
                setIsError(true);
            } finally {
                setIsFetching(false);
            }
            return [];
        },
        [archetype, query, folderId, teamId, getListFunction, setIsFetching, setIsError, fullList],
    );

    useEffect(() => {
        setIsInvalidated(true);


    }, [archetype, query, folderId, teamId, setIsInvalidated]);

    useEffect(() => {
        if (!isNeedToFetch) { return; }
        let didCancel = false;
        setIsFetching(true);
        setIsLazyFetching(true);
        const fetchData = async (page: number) => {
            setTimeout(async () => {
                const [newList, total = 0] = await fetchList(page);
                if (didCancel) {
                    return;
                }
                setTotalItems(total);
                setList((lst) => {
                    setIsFetching(false);
                    if(newList.length == perPage) {
                        fetchData(page + 1);
                    } else {
                        setIsInvalidated(false);
                        setIsNeedToFetch(false);
                        setIsLazyFetching(false);
                    }
                    return page === 1 ? (newList || []) : [...lst, ...newList];
                });
                page++;
            }, 0);
        };

        fetchData(1);
        return () => {
            didCancel = true;
        };
    }, [isNeedToFetch]);

    useEffect(() => {
        if (isInvalidated && !isFetching && !isError) {
            setIsNeedToFetch(true);
        }
    }, [isInvalidated, isFetching, isError, fetchList, setIsInvalidated, setList]);

    const invalidate = useCallback(() => {
        setIsInvalidated(true);
    }, [setIsInvalidated]);

    return [
        list,
        {
            isFetching,
            isLazyFetching,
            isError,
            isInvalidated,
            totalItems
        },
        {
            invalidate,
        }
    ];
}
