import React, { useCallback } from 'react';
import { EmailCreateForm } from 'components/user/email/create/form';
import { LetterCreateForm } from 'components/user/letters/create/form';
import { SmsCreateForm } from 'components/user/sms/create/form';
import { AutoCommunicationErrors, AutoCommunicationState } from './BulkOperationAutoCommunicationConfigurator';
import { Panel, PanelGroup } from 'react-bootstrap';
import _ from 'services/i18n';
import './AutoCommunicationAccordion.scss';
import 'font-awesome/css/font-awesome.min.css';
import lodash from 'lodash';
import AutoCommunicationPanelHeader from './AutoCommunicationPanelHeader';
import { CompositionService } from "services/composition.service";

type AutoCommunicationAccordionProps = { 
    formState: AutoCommunicationState;
    onFormStateChange: (formState: AutoCommunicationState) => void;
    errors: AutoCommunicationErrors;
    onErrors: (errors: AutoCommunicationErrors) => void;
    smsTemplateService?: CompositionService
}

const AutoCommunicationAccordion = ({
    formState,
    onFormStateChange,
    errors,
    onErrors,
    smsTemplateService
}: AutoCommunicationAccordionProps) => {

    const toggleCommunication = (communicationType: string) => {
        const state = lodash.cloneDeep(formState);

        state[`${communicationType}`].enabled = !state[`${communicationType}`].enabled;
        onFormStateChange(state);
    };

    const handlePartialFormUpdate = (communicationType: string, partialUpdate) => {
        const state = lodash.cloneDeep(formState);

        state[`${communicationType}`].data = {
            ...state[`${communicationType}`].data,
            ...partialUpdate
        };
        onFormStateChange(state);
    };
        
    return (
        <div className="auto-communication">
            <div className="info-message" role="alert">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                <b>{_`Select and fill out at least one permitted communication method, and the first viable selected communication method will be used for each patient based on the data held about them.`}</b>
            </div>
            <PanelGroup id="auto-communication-panels">
                <Panel eventKey="1">
                    <AutoCommunicationPanelHeader 
                        title={_`Email - Registered Patient`}
                        hasErrors={errors.emailIfRegistered.length > 0}
                        enabled={formState.emailIfRegistered.enabled}
                        setEnabled={() => toggleCommunication('emailIfRegistered')}
                    />
                    <Panel.Body collapsible>
                        <EmailCreateForm
                            id='bo-auto-comms-create-email-registered'
                            loading={false}
                            errors={errors.emailIfRegistered}
                            onChange={(partialState) =>  handlePartialFormUpdate('emailIfRegistered', partialState)}
                            form={formState.emailIfRegistered.data}
                            onSubmit={(f) => f.preventDefault()}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey="2">
                    <AutoCommunicationPanelHeader 
                        title={_`Email - Non Registered Patient`}
                        hasErrors={errors.emailIfUnregistered.length > 0}
                        enabled={formState.emailIfUnregistered.enabled}
                        setEnabled={() => toggleCommunication('emailIfUnregistered')}
                    />
                    <Panel.Body collapsible>
                        <>
                            <p className='warning-message'>{_`NOTE: Please do not include personal details or specific medical or diagnosis information in this email, as until the patient has registered we cannot confirm the correct person will see it.`}</p>
                            <EmailCreateForm
                                id='bo-auto-comms-create-email-unregistered'
                                loading={false}
                                errors={errors.emailIfUnregistered}
                                onChange={(partialState) =>  handlePartialFormUpdate('emailIfUnregistered', partialState)}
                                form={formState.emailIfUnregistered.data}
                                onSubmit={(f) => f.preventDefault()}
                            />
                        </>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="3">
                    <AutoCommunicationPanelHeader 
                        title={_`SMS`}
                        hasErrors={errors.sms.length > 0}
                        enabled={formState.sms.enabled}
                        setEnabled={() => toggleCommunication('sms')}
                    />
                    <Panel.Body collapsible>
                        <SmsCreateForm
                            id='bo-auto-comms-create-sms'
                            loading={false}
                            errors={errors.sms}
                            onChange={(partialState) =>  handlePartialFormUpdate('sms', partialState)}
                            form={formState.sms.data}
                            onSubmit={(f) => f.preventDefault()}
                            smsTemplateService={smsTemplateService}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey="4">
                    <AutoCommunicationPanelHeader 
                        title={_`Letter`}
                        hasErrors={errors.letter.length > 0}
                        enabled={formState.letter.enabled}
                        setEnabled={() => toggleCommunication('letter')}
                    />
                    <Panel.Body collapsible>
                        <LetterCreateForm
                            id='bo-auto-comms-create-letter'
                            errors={errors.letter}
                            onChange={(partialState) =>  handlePartialFormUpdate('letter', partialState)}
                            form={formState.letter.data}
                            onSubmit={(f) => f.preventDefault()}
                            hideSubmit={true}
                            onErrors={useCallback((err) => {
                                onErrors({ ...errors, letter: { ...errors.letter, ...err } });
                            }, [errors, onErrors])}
                        />
                    </Panel.Body>
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default AutoCommunicationAccordion;
