import React, { useEffect, useState } from 'react';

import _ from 'services/i18n';
import { terService } from 'services/ter.service';
import { browserHistory, Link } from 'react-router';
import {
    AutoCommunicationErrors,
    AutoCommunicationState
} from 'components/bulk-operations/auto-communication/BulkOperationAutoCommunicationConfigurator';
import AutoCommunicationAccordion from 'components/bulk-operations/auto-communication/AutoCommunicationAccordion';
import { normalise as normaliseEmail, validate as validateEmail } from 'components/user/email/create/validator';
import { normalise as normaliseSms, validate as validateSms } from 'components/user/sms/create/validator';
import { normalise as normaliseLetter, validate as validateLetter } from 'components/user/letters/create/validator';
import * as TER from 'models/TriggerEngineRequestContent';
import BulkOperationConfigurationComponent from 'components/bulk-operations/BulkOperationConfigurationComponent';
import { useEnabledBulkOperations } from 'components/bulk-operations/BulkOperationPreferences';
import { BulkOperationSendLetterConfigurator } from 'components/bulk-operations/BulkOperationSendLetterConfigurator';
import { userPreferencesService } from 'services/user-preferences.service';
import predefinedSmsService from 'services/predefined-sms.service';
import { folderService } from 'services/folder.service';
import ErrorView from 'components/errorbox';

const initialFormState = (
    digitalCommsEnabled: boolean,
    lettersEnabled: boolean,
    registered: boolean
): AutoCommunicationState => {
    return {
        emailIfRegistered: {enabled: digitalCommsEnabled && registered, data: {subject: '', htmlContent: '', plainTextContent: ''}},
        emailIfUnregistered: {enabled: digitalCommsEnabled && !registered, data: {subject: '', htmlContent: '', plainTextContent: ''}},
        sms: {enabled: digitalCommsEnabled, data: {message: ''}},
        letter: {enabled: lettersEnabled, data: {htmlContent: ''}}
    };
};

const initialErrors: AutoCommunicationErrors = {
    emailIfRegistered: [],
    emailIfUnregistered: [],
    general: [],
    letter: [],
    sms: []
}

const validate = (
    formState: AutoCommunicationState,
    onErrors: (errors: AutoCommunicationErrors) => unknown,
    digitalCommsEnabled: boolean,
    lettersEnabled: boolean,
    registered: boolean
) => {
    const emailIfRegisteredEnabled = digitalCommsEnabled && registered && formState.emailIfRegistered.enabled;
    const emailIfUnregisteredEnabled = digitalCommsEnabled && !registered && formState.emailIfUnregistered.enabled;
    const smsEnabled = digitalCommsEnabled && formState.sms.enabled;
    const letterEnabled = lettersEnabled && formState.letter.enabled;

    let validationErrors = initialErrors;

    if (!emailIfRegisteredEnabled && !emailIfUnregisteredEnabled && !smsEnabled && !letterEnabled) {
        const error = _`Must have at least one form of communication enabled.`;
        validationErrors = { ...validationErrors, general: [...validationErrors.general, error] };
    }

    if (emailIfRegisteredEnabled) {
        validateEmail(formState.emailIfRegistered.data, (errors) => {
            validationErrors = { ...validationErrors, emailIfRegistered: errors };
        });
    }
    if (emailIfUnregisteredEnabled) {
        validateEmail(formState.emailIfUnregistered.data, (errors) => {
            validationErrors = { ...validationErrors, emailIfUnregistered: errors };
        });
    }
    if (smsEnabled) {
        validateSms(formState.sms.data, (errors) => {
            validationErrors = { ...validationErrors, sms: errors };
        });
    }
    if (letterEnabled) {
        validateLetter(formState.letter.data, (errors) => {
            validationErrors = { ...validationErrors, letter: errors };
        });
    }

    onErrors(validationErrors);

    return validationErrors.general.length === 0 && validationErrors.emailIfRegistered.length === 0 &&
        validationErrors.emailIfUnregistered.length === 0 &&
        validationErrors.sms.length === 0 && validationErrors.letter.length === 0;
};

const getTriggerEngineRequestContent = (formState: AutoCommunicationState): TER.AutoCommunicationContent => {
    const content = {};

    if (formState.emailIfRegistered.enabled) {
        content['emailIfRegistered'] = normaliseEmail(formState.emailIfRegistered.data);
    }
    if (formState.emailIfUnregistered.enabled) {
        content['emailIfUnregistered'] = normaliseEmail(formState.emailIfUnregistered.data);
    }
    if (formState.sms.enabled) {
        content['sms'] = normaliseSms(formState.sms.data);
    }
    if (formState.letter.enabled) {

        const { htmlContent, stationery, template } = normaliseLetter(formState.letter.data);

        const templateName = template ? template.yamlFilename : '';

        content['letter'] = {
            deliveryType: 'Standard',
            productType: 'A4Letter',
            duplex: true,
            mono: false,
            pages: [
                {
                    page: 1,
                    header: stationery.header.htmlFilename,
                    body: htmlContent,
                    footer: stationery.footer.htmlFilename,
                },
            ],
            templateName: templateName,
            assets: template ? template.assets : undefined
        };
    }

    return content;
}

export const PatientAutoCommunicationCreateForm = (props: { folderId: number, onCompletionRedirect: string }) => {
    const [registered, setRegistered] = useState<boolean>(false);
    const [digitalCommsEnabled, setDigitalCommsEnabled] = useState<boolean>(false);
    const enabledBulkOperations: BulkOperationConfigurationComponent<unknown>[] = useEnabledBulkOperations();
    const lettersEnabled = enabledBulkOperations
        .some(configurationComponent => configurationComponent instanceof BulkOperationSendLetterConfigurator);
    useEffect(() => {
        userPreferencesService.getFirst({ role: undefined, folderId: props.folderId })
            .then(preferencesComposition => setDigitalCommsEnabled(!preferencesComposition.content.opted_out_of_digital_communications))
    }, [props.folderId]);

    const [currentErrors, onErrors] = useState<AutoCommunicationErrors>(initialErrors);
    const [ currentFormState, onFormStateChange ] = useState(initialFormState(digitalCommsEnabled, lettersEnabled, registered));

    useEffect(() => {
        folderService.getFolderById({ role: undefined, folderId: props.folderId })
            .then((folder) => {
                setRegistered(folder.status === 'registered');
            });
    }, [props.folderId]);

    useEffect(() => {
        onFormStateChange(initialFormState(digitalCommsEnabled, lettersEnabled, registered));
    }, [digitalCommsEnabled, lettersEnabled, registered]);

    const onSubmit = () => {
        if (validate(currentFormState, onErrors, digitalCommsEnabled, lettersEnabled, registered)) {
            const terContent = getTriggerEngineRequestContent(currentFormState);
            terService.createTer({
                action: 'autoCommunication',
                folderId: props.folderId,
                data: terContent
            }).then(() => {
                browserHistory.push(`${props.onCompletionRedirect}`);
            })
        }
    }

    const updateFormState = (value: React.SetStateAction<AutoCommunicationState>): void => {
        const newFormState = {...currentFormState, ...value};
        newFormState.emailIfRegistered.enabled = newFormState.emailIfRegistered.enabled && digitalCommsEnabled && registered;
        newFormState.emailIfUnregistered.enabled = newFormState.emailIfUnregistered.enabled && digitalCommsEnabled && !registered;
        newFormState.sms.enabled = newFormState.sms.enabled && digitalCommsEnabled;
        newFormState.letter.enabled = newFormState.letter.enabled && lettersEnabled;
        onFormStateChange(newFormState);
    }

    return (
        <>
            <div className="top-bar">
                <Link to={props.onCompletionRedirect}>
                    {_`Back to communications`}</Link>
            </div>
            <AutoCommunicationAccordion
                formState={currentFormState}
                onFormStateChange={updateFormState}
                errors={currentErrors}
                onErrors={onErrors}
                smsTemplateService={predefinedSmsService}
            />
            <ErrorView errors={currentErrors.general}/>
            <button onClick={onSubmit} className='btn btn-primary'>
                {_`Send Auto Communication`}
            </button>
        </>
    );
}