import React from 'react';
import BasePage from 'components/page/base';
import _ from 'services/i18n';
import { PatientAutoCommunicationCreateForm } from 'components/user/communications/patientForm';

export default class extends BasePage {
    constructor (props) {
        super(props);
    }

    pageTitle () {
        return _`Create new ${this.$p()} Auto Communication | MyPathway Clinical Portal`;
    }

    render () {
        const backRoute = `/clinical_portal/folder/${this.props.folderId}/patient/communications`;
        return (<PatientAutoCommunicationCreateForm folderId={this.props.folderId} onCompletionRedirect={backRoute} />)
    }
}
