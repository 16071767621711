/* eslint @typescript-eslint/camelcase: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import { IndexRoute, IndexRedirect, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { MSKRoute as Route } from 'common/routes/MSKRoute';

import { ClinicalPortalTemplate } from 'layouts/clinical_portal/ClinicalPortalTemplate';
import Folder_Check from 'middleware/clinical_portal/folder-check';

import IndexPage from 'pages/clinical_portal';

import Patients from 'pages/clinical_portal/patients';
import Patients_Index from 'pages/clinical_portal/patients';

import Patient_Index from 'pages/clinical_portal/folder/_folderId/patient';

import Appointments_Index from 'pages/clinical_portal/folder/_folderId/patient/appointments';
import Appointment_Item from 'pages/clinical_portal/folder/_folderId/patient/appointments/_id';

import OverdueQuestionnaire_Index from 'pages/clinical_portal/overdue-questionnaires';

import UserFeedback_Index from 'pages/clinical_portal/user-feedback';
import UserFeedback_View from 'pages/clinical_portal/folder/_folderId/user-feedback';

import Invite_Accept from 'pages/clinical_portal/invite/accept/_token';

// :_patientId
import PatientDashboard from 'pages/clinical_portal/folder/_folderId';

import Login from 'pages/clinical_portal/login';
import MFA from 'pages/clinical_portal/mfa';
import Logout from 'pages/clinical_portal/logout';
import SignUp from 'pages/clinical_portal/sign-up';
import PasswordReset from 'pages/clinical_portal/password-reset';
import ForgotPassword from 'pages/clinical_portal/forgot-password';
import ConfirmSignup from 'pages/clinical_portal/confirm';

import Not_Found from 'pages/clinical_portal/not_found';

import Reports from 'pages/clinical_portal/reports';
import Reports_Index from 'pages/clinical_portal/reports/main';

import Reports_PatientSignup from 'pages/clinical_portal/reports/patient-signup';
import Reports_PatientInvites from 'pages/clinical_portal/reports/patient-invites';
import Reports_Referrals from 'pages/clinical_portal/reports/referrals';
import Reports_CompletedEQ5Ds from 'pages/clinical_portal/reports/completed-eq5ds';
import Reports_OverdueEQ5Ds from 'pages/clinical_portal/reports/overdue-eq5ds';
import Reports_IssueCompletionEQ5Ds from 'pages/clinical_portal/reports/issue-completion-eq5ds';

import Worklists from 'pages/clinical_portal/my-team/worklists';
import Worklists_TransportAndInterpreter from 'pages/clinical_portal/my-team/worklists/transport-and-interpreter';
import { AntenatalWorklistPage } from 'features/antenatal/AntenatalWorklistPage';
import { AttendAnywherePage } from 'features/attend-anywhere/AttendAnywherePage';

import { ProfileContainer } from 'features/profile/ProfileContainer';
import Profile_Photograph from 'features/profile/photograph';
import Profile_Password from 'features/profile/password';
import { ProfileChangeEmail } from 'features/profile/ProfileChangeEmail';
import Profile_MFA from 'features/profile/mfa';

import MyTeamPage_Index from 'pages/clinical_portal/my-team';
import PatientPathwayPage_Index from 'pages/clinical_portal/folder/_folderId/patient/pathway';

import Patient_MessagesPage_Index from 'pages/clinical_portal/folder/_folderId/patient/messages';
import Patient_MessagesPage_Show from 'pages/clinical_portal/folder/_folderId/patient/messages/_messageId';
import Patient_MessagesPage_Create from 'pages/clinical_portal/folder/_folderId/patient/messages/create';

import Bulk_Operations from 'pages/clinical_portal/bulk-operations/index';
import { BulkOperationView } from 'pages/clinical_portal/bulk-operations/BulkOperationView';

import Patient_ReferralList_Index from 'pages/clinical_portal/folder/_folderId/patient/referrals/list';
import Patient_TriageDecision
    from 'pages/clinical_portal/folder/_folderId/patient/referrals/_referralUuid/enter-triage-decision';
import Patient_Resources_Index from 'pages/clinical_portal/folder/_folderId/patient/resources';

import Patient_Questionnaires_Index from 'pages/clinical_portal/folder/_folderId/patient/questionnaires';
import Patient_Questionnaires_Show
    from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/_questionnaireId';
import Patient_Questionnaires_Allocate from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/create';
import Patient_Simple_Questionnaires_Allocate from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/create-simple';
import Patient_Questionnaires_Compare
    from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/compare-questionnaires';
import CompareEq5d from 'pages/clinical_portal/folder/_folderId/patient/questionnaires/CompareEq5d';
import Patient_DashboardPage_Index from 'features/patient/patient-dashboard';
import Patient_Devices_Index from 'features/patient/devices';

import { PatientGoalsTab } from 'features/patient/goals/PatientGoalsTab';
import { PatientGoalsShowTab } from 'features/patient/goals/PatientGoalsShowTab';

import AuthCheckMiddleware from 'middleware/authorize-check';

import Settings_Page from 'pages/clinical_portal/folder/_folderId/patient/settings';
import UserNotes_Page from 'pages/clinical_portal/folder/_folderId/patient/user-notes';
import Observations_Page from 'pages/clinical_portal/folder/_folderId/patient/observations';

import { PatientCommunicationsPage } from 'pages/clinical_portal/folder/_folderId/patient/communications/PatientCommunicationsPage';
import Patient_AutoCommsPage_Create from 'pages/clinical_portal/folder/_folderId/patient/communications/create';
import { ResultsPage } from 'pages/clinical_portal/folder/_folderId/patient/results/ResultsPage';
import { QuestionnaireResultsPage } from 'features/patient/questionnaire-results/QuestionnaireResultsPage';
import { MNDDepressedPatientsWorklistPage } from 'team-specific-features/mnd-depressed-patients-worklist/MNDDepressedPatientsWorklistPage';
import { MndWorklistPage } from 'team-specific-features/mnd-worklist/MndWorklistPage';
import { PatientGoalCreate } from 'features/patient/goals/PatientGoalCreate';
import { CareEventDialog } from 'features/patient/care-event/CareEventDialog';

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const ClinicalTemplateWithRedux = connect(undefined, mapDispatchToProps)(ClinicalPortalTemplate);

const routes = (
    <Route path="clinical_portal">
        <Route path="sign-up" ignoreTeamPreferences={true} component={SignUp} />
        <Route path="confirm/:token" ignoreTeamPreferences={true} component={ConfirmSignup} />
        <Route path="forgot-password" ignoreTeamPreferences={true} component={ForgotPassword} />
        <Route path="password-reset/:token" ignoreTeamPreferences={true} component={PasswordReset} />
        <Route path="login" ignoreTeamPreferences={true} component={Login} />
        <Route path="mfa" ignoreTeamPreferences={true} component={MFA} />
        <Route path="invite/accept/:token" ignoreTeamPreferences={true} component={Invite_Accept} />

        <Route component={(props) => <AuthCheckMiddleware {...props} redirectTo={'/clinical_portal/login'} />}>

            <Route path="logout" ignoreTeamPreferences={true} component={Logout} />

            <Route component={ClinicalTemplateWithRedux}>
                <IndexRoute component={IndexPage} onEnter={(nextState, replace) => {
                    if(!nextState.location.query.filter) {
                        replace(`${nextState.location.pathname}?filter=registered&page=1`);
                    }
                }} />

                <Route path="my-team">
                    <IndexRedirect to="patients" />
                    <Route component={MyTeamPage_Index}>
                        <Route path="patients">
                            <IndexRoute component={Patients_Index} />
                            <Route path="*" component={Not_Found} />
                        </Route>
                        <Route path="overdue-questionnaires">
                            <IndexRoute component={OverdueQuestionnaire_Index} />
                            <Route path="*" component={Not_Found} />
                        </Route>
                        <Route path="reports" component={Reports} baseRoute={'/clinical_portal/my-team/reports'}>
                            <IndexRoute component={Reports_Index} baseRoute={'/clinical_portal/my-team/reports'} />
                            <Route path="patient-signup" component={Reports_PatientSignup} />
                            <Route path="patient-invites" component={Reports_PatientInvites} />
                            <Route path="referrals" component={Reports_Referrals} />
                            <Route path="completed-eq5ds" component={Reports_CompletedEQ5Ds} />
                            <Route path="overdue-eq5ds" component={Reports_OverdueEQ5Ds} />
                            <Route path="issue-completion-eq5ds" component={Reports_IssueCompletionEQ5Ds} />
                        </Route>
                        <Route path="user-feedback">
                            <IndexRoute component={UserFeedback_Index} />
                            <Route path="*" component={Not_Found} />
                        </Route>
                        <Route path="worklists" component={Worklists}>
                            <Route path="transport-interpreter" component={Worklists_TransportAndInterpreter} />
                            <Route path="antenatal" component={AntenatalWorklistPage} />
                            <Route path="mnd" component={MNDDepressedPatientsWorklistPage} />
                            <Route path="attend-anywhere" component={AttendAnywherePage} />
                            <Route path="mnd-worklist" component={MndWorklistPage} />
                        </Route>
                    </Route>
                </Route>
                <Route ignoreTeamPreferences={true}>
                    <Route path="overdue-questionnaires">
                        <IndexRoute component={OverdueQuestionnaire_Index} />
                    </Route>

                    <Route path="bulk-operations">
                        <IndexRoute component={Bulk_Operations} />
                        <Route path=":uuid" component={BulkOperationView} />
                    </Route>

                    <Route path="profile">
                        <IndexRedirect to={'change-email'} />
                        <Route component={ProfileContainer}>
                            <Route path="photograph" component={Profile_Photograph} portal='clinical_portal' />
                            <Route path="password" component={Profile_Password} portal='clinical_portal' />
                            <Route path="change-email" component={ProfileChangeEmail} />
                            <Route path="mfa" component={Profile_MFA} />
                        </Route>
                    </Route>

                    <Route path="patients">
                        <IndexRoute component={Patients} />
                        <Route path="*" component={Not_Found} />
                    </Route>

                    <Route path="reports" component={Reports}>
                        <IndexRoute component={Reports_Index} />
                        <Route path="patient-signup" component={Reports_PatientSignup} />
                        <Route path="patient-invites" component={Reports_PatientInvites} />
                        <Route path="referrals" component={Reports_Referrals} />
                        <Route path="completed-eq5ds" component={Reports_CompletedEQ5Ds} />
                        <Route path="overdue-eq5ds" component={Reports_OverdueEQ5Ds} />
                        <Route path="issue-completion-eq5ds" component={Reports_IssueCompletionEQ5Ds} />
                    </Route>

                    <Route path="folder/:folderId" component={Folder_Check}>
                        <IndexRoute component={PatientDashboard} />

                        <Route path="patient" component={Patient_Index}>
                            <Route path="dashboard">
                                <IndexRoute component={Patient_DashboardPage_Index} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="pathway">
                                <IndexRoute component={PatientPathwayPage_Index} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="appointments">
                                <IndexRoute component={Appointments_Index} />
                                <Route path=":uuid" component={Appointment_Item} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="messages">
                                <IndexRoute component={Patient_MessagesPage_Index} />
                                <Route path="create" component={Patient_MessagesPage_Create} />
                                <Route path=":messageId" component={Patient_MessagesPage_Show} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="care-event">
                                <Route path=":teamId" component={CareEventDialog} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="resources">
                                <IndexRoute component={Patient_Resources_Index} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="questionnaires">
                                <IndexRoute component={Patient_Questionnaires_Index} />
                                <Route path="create" component={Patient_Questionnaires_Allocate} />
                                <Route path="create-simple" component={Patient_Simple_Questionnaires_Allocate} />
                                <Route path="compare-eq5d" component={(props) => (
                                    <CompareEq5d {...props}
                                        linkBack={'/patient/questionnaires'} />
                                )} />
                                <Route path="compare" component={Patient_Questionnaires_Compare} />
                                <Route path=":id" component={Patient_Questionnaires_Show} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="results">
                                <IndexRoute component={ResultsPage} />
                                <Route path="*" component={Not_Found} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="graphs">
                                <IndexRoute component={QuestionnaireResultsPage} />
                                <Route path="*" component={Not_Found} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="referrals">
                                <IndexRoute component={Patient_ReferralList_Index} />
                                <Route path=":referralUuid">
                                    <Route path="enter-triage-decision" component={Patient_TriageDecision} />
                                    <Route path="*" component={Not_Found} />
                                </Route>
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="goals">
                                <IndexRoute component={PatientGoalsTab} />
                                <Route path="create" component={PatientGoalCreate}/>
                                <Route path="edit" >
                                    <Route path=":goalUuid" component={PatientGoalCreate}/>
                                </Route>
                                <Route path=":goalUuid" component={PatientGoalsShowTab} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="settings">
                                <IndexRoute component={Settings_Page} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="communications">
                                <IndexRoute component={PatientCommunicationsPage} />
                                <Route path="create" component={Patient_AutoCommsPage_Create} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="user-notes">
                                <IndexRoute component={UserNotes_Page} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="observations">
                                <IndexRoute component={Observations_Page} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="devices">
                                <IndexRoute component={Patient_Devices_Index} />
                                <Route path="*" component={Not_Found} />
                            </Route>
                            <Route path="*" component={Not_Found} />
                        </Route>

                        <Route path="user-feedback">
                            <IndexRoute component={UserFeedback_Index} />
                            <Route path=":uuid" component={UserFeedback_View} />
                            <Route path="*" component={Not_Found} />
                        </Route>

                        <Route path="*" component={Not_Found} />
                    </Route>
                    <Route path="*" component={Not_Found} />
                </Route>
            </Route>
        </Route>
    </Route>
);

export default routes;
