/** @module controllers/admin_portal/questionniare-templates/create */

import React from 'react';
import View from './view';
import BaseComponent from 'components/BaseComponent';
import {browserHistory} from 'react-router';
import _ from 'services/i18n';
import lodash from 'lodash';
import { questionnaireService } from 'services/questionnaire.service';

export default class CreateQuestionnaireTemplateComponent extends BaseComponent {
    /**
     * Setup the component
     */
    constructor(props){
        super(props);

        this.state = {
            templateList: [],
            compositionsList: [],
            isComposition: false,
            isTemplate: false,
            errors: [],
            loading: false,
            brandNewTemplate: 1,
            showModal: false,
            parentId: 0,
            name: '',
            filename: '',
            fileContent: ''
        };

        this.View = View;

        this.bindEvents([
            'Submit',
            'Change',
            'ClickChangeFile',
            'CloseChangeModal',
            'UploadChangeModal'
        ]);
    }

    /**
     * Run any Server Calls
     */
    componentDidMount(){
        this.loadComponent();
    }

    loadComponent(){
        this.getAvailableQuestionnaireTypes()
            .then(({templates,compositions}) =>{
                this.setState({
                    templateList: templates,
                    compositionsList: compositions,
                    loading: false
                });
            });
    }

    getAvailableQuestionnaireTypes(){
        return questionnaireService.getTemplates({limit: Infinity})
            .then((templates) => {
                return templates.reduce((result, questionnaire) => {
                    if(questionnaire.document_type === 'questionnaire') {
                        result.compositions.push(questionnaire);
                    } else {
                        result.templates.push(questionnaire);
                    }
                    return result;
                }, {templates: [], compositions: []});
            });
    }

    handleClickChangeFile(){
        this.setState({
            showModal: true
        });
    }

    handleCloseChangeModal(){
        this.setState({
            showModal: false
        });
    }

    handleUploadChangeModal(data, filename){
        this.setState({
            loading: true,
            showModal: false,
            filename: filename,
            fileContent: data
        });
    }

    /**
     * Update the state for the given user input
     * @param {Object} target - User generated event
     */
    handleChange({target}){
        const newState = {};

        switch (target.id) {
            case 'parentId':
                const selectedOption = lodash.find(target.options, (option) =>{
                        return option.selected === true;
                    }) || {};
                
                const documentType = lodash.get(selectedOption, 'dataset.type');
                    
                newState.isComposition = documentType === 'composition';
                newState.isTemplate = documentType === 'template';

                newState[target.id] = target.value;
                this.setState(newState);
                break;
            case 'name':
            case 'brandNewTemplate':
                newState[target.id] = target.value;
                this.setState(newState);
                break;
        }
    }

    /**
     * Validate the form and submit the message via the API
     * @param {Object} e - The form submit event
     */
    handleSubmit(e){
        e.preventDefault();

        const name = this.state.name.trim();

        let errors = [];

        if (+this.state.brandNewTemplate === 1 && name === '') {
            errors.push(_`Template name is required`);
        }

        if (+this.state.brandNewTemplate === 1
            && !!this.state.compositionsList.find((template) => template.content.name === name)) {
            errors.push(_`A questionnaire with this name already exists`);
        }

        if (+this.state.brandNewTemplate === 0 && this.state.parentId === '') {
            errors.push(_`Select an existing template`);
        }

        if (this.state.filename === '') {
            errors.push(_`No file has been selected`);
        }

        let json;
        try {
            json = JSON.parse(this.state.fileContent);
        } catch (e) {
            errors.push(_`File does not contain valid JSON`);
        }

        if (errors.length > 0) {
            this.setState({
                errors: errors
            });
            return;
        }

        const newState = {
            sendingMessage: true,
            errors: [],
            isComposition: this.state.isComposition,
            isTemplate: this.state.isTemplate
        };

        if (+this.state.brandNewTemplate === 1) {
            const isComposition = lodash.has(json, 'details.sections');
            newState.isComposition = isComposition;
            newState.isTemplate = !isComposition;

            if (newState.isComposition){
                json.name = name;
            }
        }
        if (+this.state.brandNewTemplate === 0){
            if (newState.isComposition){
                const compositionToUpdate = lodash.find(this.state.compositionsList,(composition) => {
                    return composition.uuid === this.state.parentId;
                });
                json.name = lodash.get(compositionToUpdate,'content.name',json.name);
            }
        }

        this.$setState(newState)
            .then(() =>{
                this.createOrUpdateQuestionnaireTemplate({
                        json,
                        name
                    })
                    .then(() =>{
                        browserHistory.push('/admin_portal/questionnaire-templates');
                    });
            });
    }

    createOrUpdateQuestionnaireTemplate({json, name}){

        if (!this.state.isTemplate && !this.state.isComposition){
            return Promise.reject();
        }

        if (this.state.isTemplate) {
            const content = {
                jsonString: this.state.fileContent,
                jsonObject: json,
                parent_id: +this.state.brandNewTemplate === 0 ? this.state.parentId : 0,
                name: +this.state.brandNewTemplate === 1 ? name : ''
            };

            return questionnaireService.createCoopQuestionnaire(content);
        }

        if (this.state.isComposition) {
            if (+this.state.brandNewTemplate === 1) {
                return questionnaireService.createTemplate({archetypeName: 'questionnaire', content: json});
            } else {
                return this.getQuestionnaireCurrentVersion(this.state.parentId)
                    .then((version) => {
                        json.version = version+1;
                        return questionnaireService.updateTemplate({uuid: this.state.parentId,archetypeName: 'questionnaire', content: json});
                    });
            }
        }
    }

    /**
     *
     * @param uuid
     */
    getQuestionnaireCurrentVersion(uuid) {
        return questionnaireService.getTemplateByUuid({uuid, archetypeName: 'questionnaire'})
            .then((response) => {
                return response.message;
            })
            .then((questionnaire) => {
                return questionnaire.content.version;
            });
    }
}
