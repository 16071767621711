import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import PageTitle from 'page-title';
import _ from 'services/i18n';
import {
    defaultNumberSort,
    Grid,
    GridBody,
    GridCell,
    GridHeader,
    GridHeaderCell,
    GridHeaderRow,
    GridRow,
    GridSortState,
    SortOrder
} from 'common/ui/grid';
import { PaginationView } from 'components/pagination';
import Modal from 'common/ui/modal';
import {
    COMMUNICATION_TYPE,
    UserCommunication,
    UserCommunicationEmail,
    UserCommunicationHtmlLetter,
    UserCommunicationMeshLetter,
    UserCommunicationPushNotification,
    UserCommunicationSMS,
} from 'models/UserCommunication';
import './patient-communication-page.scss';
import { isEmpty } from 'lodash';
import { Loading } from 'common/ui/alert-boxes';
import { getCommunicationInfo } from './communications-helper';
import { Filter } from 'common/ui/grid/paginatedTableReducer';
import mediaResourceService from 'services/media-resource/media-resource.service';
import { PlainHtmlToIframe } from 'common/ui/plain-html-to-iframe/PlainHtmlToIframe';
import { DateTime } from 'common/datetime/DateTime';
import { useTableFilter } from 'common/ui/grid/useTableFilter';
import { useTableSort } from 'common/ui/grid/useTableSort';
import { usePagination } from 'common/ui/usePagination';
import { usePreparedCommunications } from 'pages/clinical_portal/folder/_folderId/patient/communications/usePreparedCommunications';
import { LetterPdfPreview } from 'components/user/letters/create/pdfPreview';
import { Link } from 'react-router';
import { useEnabledBulkOperations } from 'components/bulk-operations/BulkOperationPreferences';
import BulkOperationConfigurationComponent from 'components/bulk-operations/BulkOperationConfigurationComponent';
import {
    BulkOperationAutoCommunicationConfigurator
} from 'components/bulk-operations/auto-communication/BulkOperationAutoCommunicationConfigurator';

const initialSortState = {
    date: SortOrder.ASC,
};

const filterSelector = {
    date: item => item.dateFilter,
};

const sortSelector = {
    date: item => item.dateSort,
};

const sortHandlers = {
    date: defaultNumberSort,
};

export const PatientCommunicationsPage: FC<{ teamId: number; folderId: number }> = ({ teamId, folderId }) => {

    const [state, setState] = useState<{ list: UserCommunication[]; loading: boolean }>({ list: [], loading: true });

    useEffect(() => {
        PageTitle.setTitle(
            'Communications | PHR Clinical Portal'
        );

        let cancelled = false;
        getCommunicationInfo({ teamId, folderId })
            .then((comms) => {
                if (!cancelled) {
                    setState({ loading: false, list: comms });
                }
            });
        return () => {
            cancelled = true;
        };
    }, [teamId, folderId]);

    const enabledBulkOperations: BulkOperationConfigurationComponent<unknown>[] = useEnabledBulkOperations();
    const autoCommsEnabled = enabledBulkOperations
        .some(configurationComponent => configurationComponent instanceof BulkOperationAutoCommunicationConfigurator);

    return (
        <>
            <div className="top-bar">
                {autoCommsEnabled && <Link className={'top-bar--patient__left'}
                       to={`/clinical_portal/folder/${folderId}/patient/communications/create`}
                >{_`Send Auto Communication`}</Link>}
            </div>
            <PatientCommunications
                list={state.list}
                loading={state.loading}
            />
        </>
    );
};

export interface PatientCommsProps {
    list: UserCommunication[];
    loading: boolean;
}
export const PatientCommunications: FC<PatientCommsProps> = ({ list, loading }) => {

    const preparedItems = usePreparedCommunications(list);

    const { onFilter, filteredItems, filterState } = useTableFilter({
        items: preparedItems,
        selectors: filterSelector,
    });

    const { onSort, sortedItems, sortState } = useTableSort({
        items: filteredItems,
        initialSortState,
        selectors: sortSelector,
        sortHandlers,
    });

    const { items: paginatedItems, total, onPageChange, page } = usePagination({
        items: sortedItems,
        perPage: 8,
        currentPage: 1
    });

    return (
        <PatientCommunicationsView
            onFilter={onFilter}
            onSort={onSort}
            onPageChange={onPageChange}
            filterState={filterState}
            sortOrder={sortState}
            communicationsList={paginatedItems}
            isLoading={loading}
            page={page}
            pagesTotal={total}
        />
    );
};

interface ViewProps {
    communicationsList: UserCommunication[];
    sortOrder: GridSortState;
    filterState: Filter;
    page: number;
    pagesTotal: number;
    isLoading: boolean;
    onSort: (sort: GridSortState) => void;
    onFilter: (filter: Filter) => void;
    onPageChange: (page: number) => void;
}
export const PatientCommunicationsView: FC<ViewProps> = (params) => {

    const {
        onFilter,
        onSort,
        onPageChange,

        filterState,
        sortOrder,
        communicationsList,
        isLoading,
        page,
        pagesTotal,
    } = params;

    const [modalContent, setModalContent] = useState<UserCommunication>(void 0);

    const openDetails = useCallback((commUuid) => {
        setModalContent(communicationsList.find(({ uuid }) => uuid === commUuid));
    }, [communicationsList]);

    const closeModal = useCallback(() => {
        setModalContent(void 0);
    }, []);

    const modalViewExistsForType = (communicationType) => {
        return COMMUNICATION_TYPE.LETTER !== communicationType;
    }

    // render

    if (!isLoading && !communicationsList?.length && isEmpty(filterState)) {
        return <div className={'communication-page_empty-placeholder'}>{_`List is empty`}</div>;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Fragment>
            <Grid
                onSort={onSort}
                onFilter={onFilter}
                sortOrder={sortOrder}
                filterState={filterState}
            >
                <GridHeader>
                    <GridHeaderRow>
                        <GridHeaderCell
                            field="type"
                            sortable={true}
                            filterable={true}
                            title={_`Type`}
                        >
                            {_`Type`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="subject"
                            sortable={true}
                            filterable={true}
                            title={_`Subject`}
                        >
                            {_`Subject`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="to"
                            sortable={true}
                            filterable={true}
                            title={_`To`}
                        >
                            {_`To`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="date"
                            sortable={true}
                            filterable={true}
                            title={_`Date`}
                        >
                            {_`Date`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="status"
                            sortable={true}
                            filterable={true}
                            title={_`Status`}
                        >
                            {_`Status`}
                        </GridHeaderCell>
                        <GridHeaderCell
                            field="actions"
                            sortable={false}
                            filterable={false}
                            title={_`Actions`}
                        >
                            {_`Actions`}
                        </GridHeaderCell>
                    </GridHeaderRow>
                </GridHeader>
                <GridBody>
                    {communicationsList.map((comm) => {
                        const {
                            to,
                            type,
                            uuid,
                            status,
                            subject,
                            date,
                        } = comm;
                        return (
                            <GridRow key={uuid}>
                                <GridCell>{type}</GridCell>
                                <GridCell>
                                    {
                                        modalViewExistsForType(comm.type) ?
                                            <a href="" onClick={(e) => { e.preventDefault(); openDetails(uuid); }}>{subject}</a>
                                            : <div>{subject}</div>
                                    }
                                </GridCell>
                                <GridCell>
                                    {to}
                                </GridCell>
                                <GridCell>
                                    <DateTime format="questionnaires_date">
                                        {date}
                                    </DateTime>
                                </GridCell>
                                <GridCell>
                                    {status}
                                </GridCell>
                                <GridCell>
                                    { modalViewExistsForType(comm.type) &&
                                    (
                                        <button
                                            onClick={() => openDetails(uuid)}
                                            type={'button'}
                                            className={'btn btn-xs btn-default'}
                                        >
                                            {_`View`}
                                        </button>
                                    )}
                                </GridCell>
                            </GridRow>
                        );
                    })}
                </GridBody>
            </Grid>
            <PaginationView
                currentPage={page}
                pageCount={pagesTotal}
                onChange={onPageChange}
            />
            {modalContent && (
                <CommDetailsModal
                    content={modalContent}
                    onCloseModal={closeModal}
                />
            )}
        </Fragment>
    );
};

const CommDetailsModal: FC<{ content: UserCommunication; onCloseModal: () => void }> = ({ content, onCloseModal }) => {

    return (
        <Modal
            size={'lg'}
            title={_`Communication details`}
            onClose={onCloseModal}
            buttons={[{ label: 'Close', callback: onCloseModal }]}
        >
            <div className={'communication-details'}>
                {content.type === COMMUNICATION_TYPE.EMAIL &&
                    <EmailInfo content={content} />
                }
                {content.type === COMMUNICATION_TYPE.MESH_LETTER &&
                    <MeshLetterInfo content={content} />
                }
                {content.type === COMMUNICATION_TYPE.PUSH &&
                    <PushInfo content={content} />
                }
                {content.type === COMMUNICATION_TYPE.SMS &&
                    <SMSInfo content={content} />
                }
                {content.type === COMMUNICATION_TYPE.HTML_LETTER &&
                    <HtmlLetterInfo content={content} />
                }
            </div>
        </Modal>
    );
};

export const MeshLetterInfo: FC<{ content: UserCommunicationMeshLetter }> = ({ content }) => {
    const [xmlIsShown, showXml] = useState(false);
    const openResource = useCallback((resourceId) => {
        return mediaResourceService.getFileLink(resourceId)
            .then((url) => {
                window.open(url, '_blank');
            });
    }, []);
    const toggleXml = useCallback((e) => {
        e.preventDefault();
        showXml((prev) => !prev);
    }, []);

    return (
        <Fragment>
            <table>
                <tbody>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Type`}:</span></th>
                        <td>{content.type}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`To`}:</span></th>
                        <td>{content.to}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Date`}:</span></th>
                        <td>
                            <DateTime format="questionnaires_date">
                                {content.date}
                            </DateTime>
                        </td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Subject`}:</span></th>
                        <td>{content.subject}</td>
                    </tr>
                </tbody>
            </table>
            <hr className={'communication-details_breaker-line'} />
            <br />
            <a href="#" onClick={(e) => {
                e.preventDefault();
                openResource(content.pdfMediaResourceId);
            }}><i className={'fas fa-file-pdf'} /> Download PDF File</a>
            <br />
            <a href="#" onClick={(e) => {
                e.preventDefault();
                openResource(content.xmlMediaResourceId);
            }}><i className={'fas fa-file-code'} /> Download XML File</a>
            <br />
            <br />
            <a href="#"
                onClick={toggleXml}>{xmlIsShown ? 'Hide' : 'Show'} inline XML</a>
            <br />
            {xmlIsShown && (
                <pre>
                    {content.xml || 'empty!'}
                </pre>
            )}
            <br />
            <br />
            <span className={'communication-details_header--2'}>{_`Content`}:</span>
            <PlainHtmlToIframe className={'communication-details_iframe'}>{content.html}</PlainHtmlToIframe>
        </Fragment>
    );
};

export const EmailInfo: FC<{ content: UserCommunicationEmail }> = ({ content }) => {
    return (
        <Fragment>
            <table>
                <tbody>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Type`}:</span></th>
                        <td>{content.type}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`From`}:</span></th>
                        <td>{content.from}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`To`}:</span></th>
                        <td>{content.to}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Date`}:</span></th>
                        <td>
                            <DateTime format="questionnaires_date">
                                {content.date}
                            </DateTime>
                        </td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Subject`}:</span></th>
                        <td>{content.subject}</td>
                    </tr>
                </tbody>
            </table>
            <hr className={'communication-details_breaker-line'} />
            <br />
            <span className={'communication-details_header--2'}>{_`Content`}:</span>
            <PlainHtmlToIframe className={'communication-details_iframe'}>{content.content}</PlainHtmlToIframe>
        </Fragment>
    );
};

export const PushInfo: FC<{ content: UserCommunicationPushNotification }> = ({ content }) => {
    return (
        <Fragment>
            <table>
                <tbody>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Type`}:</span></th>
                        <td>{content.type}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`To`}:</span></th>
                        <td>{content.to}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Date`}:</span></th>
                        <td>
                            <DateTime format="questionnaires_date">
                                {content.date}
                            </DateTime>
                        </td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Subject`}:</span></th>
                        <td>{content.subject}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Status`}:</span></th>
                        <td>{content.status}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Error`}:</span></th>
                        <td>{content.error || _`No errors`}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`High Priority`}:</span></th>
                        <td>{content.highPriority ? _`Yes` : _`No`}</td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export const SMSInfo: FC<{ content: UserCommunicationSMS }> = ({ content }) => {
    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Type`}:</span></th>
                        <td>{content.type}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`To`}:</span></th>
                        <td>{content.to}</td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Date`}:</span></th>
                        <td>
                            <DateTime format="questionnaires_date">
                                {content.date}
                            </DateTime>
                        </td>
                    </tr>
                    <tr>
                        <th><span className={'communication-details_header'}>{_`Status`}:</span></th>
                        <td>{content.status}</td>
                    </tr>
                </tbody>
            </table>

            <hr className={'communication-details_breaker-line'} />
            <br />
            <span className={'communication-details_header--2'}>{_`Content`}:</span>
            <PlainHtmlToIframe className={'communication-details_iframe'}>{content.content}</PlainHtmlToIframe>
        </>
    );
};

export const HtmlLetterInfo: FC<{ content: UserCommunicationHtmlLetter }> = ({ content }) => {

    return (
        <>
            <table>
                <tbody>
                <tr>
                    <th><span className={'communication-details_header'}>{_`Type`}:</span></th>
                    <td>{content.type}</td>
                </tr>
                <tr>
                    <th><span className={'communication-details_header'}>{_`Date`}:</span></th>
                    <td>
                        <DateTime format="questionnaires_date">
                            {content.date}
                        </DateTime>
                    </td>
                </tr>
                <tr>
                    <th><span className={'communication-details_header'}>{_`Status`}:</span></th>
                    <td>{content.status}</td>
                </tr>
                </tbody>
            </table>

            <hr className={'communication-details_breaker-line'} />
            <br />
            <span className={'communication-details_header--2'}>{_`Content`}:</span>
            {content.mediaResourceUuid && (
                <LetterPdfPreview
                    fileDownloadFn={() => mediaResourceService.downloadFileContent(content.mediaResourceUuid)}
                />
            )}
            {!content.mediaResourceUuid && (
                <span>Letter is not yet available</span>
            )}
        </>
    );
};
