import { NOTIFICATION_TYPE } from 'services/push-notifications.service';
import { PersonPrimaryIdentifier } from 'models/PersonPrimaryIdentifier';
import { Label } from 'models/Label';
import { LetterTemplateAsset } from "components/user/letters/letters.interface";

export interface TriggerEngineRequestContent { [key: string]: unknown }

export interface SendTimelineMessageContent extends TriggerEngineRequestContent {
    title: string;
    content: string;
    sender_name: string;
    type: string;
}

export interface UserName {
    prefix?: string;
    suffix?: string;
    given_name?: string;
    family_name?: string;
    middle_name?: string;
}
export interface CreateUserContent extends TriggerEngineRequestContent {
    name: UserName;
    email: string;
    // eslint-disable-next-line @typescript-eslint/camelcase
    role_uuids: string[];

    // eslint-disable-next-line @typescript-eslint/camelcase
    person_primary_identifier?: PersonPrimaryIdentifier;
    gender?: string;
    label?: Label;
    phone?: string;
    dob?: string;
}

export interface AllocateResourceWithMessage extends TriggerEngineRequestContent {
    title: string;
    content: string;
    sender_name: string;
    type: string;
    resources: [{
        type: string;
        description: string;
        media_resource_uuid?: string;
        title: string;
        url?: string;
        phone?:string;
        html_content?: string;
    }];
}

export interface SendQuestionnaireContent extends TriggerEngineRequestContent {
    questionnaire_name: string;
    lozenge_title: string;
    lozenge_content: string;
    popup_title: string;
    popup_content: string;
}

export interface SendSimpleQuestionnaireContent extends TriggerEngineRequestContent {
    location: string;
    id: string;
    lozenge_title: string;
    lozenge_content: string;
    popup_title: string;
    popup_content: string;
}

export interface SendPushNotificationContent extends TriggerEngineRequestContent {
    notification?: NOTIFICATION_TYPE;
    body?: string;
    title?: string;
}

export interface SendSmsContent extends TriggerEngineRequestContent {
        message: string;
}

export interface SendEmailContent extends TriggerEngineRequestContent {
    subject: string;
    htmlContent: string;
    plainTextContent: string;
}

export interface SendPIFUContent extends TriggerEngineRequestContent {
    name: string;
    context: string;
}

export function validatePushNotificationContent(content: SendPushNotificationContent): boolean {
    if (!content.body && !content.notification && !content.title) {
        return false;
    }
    return true;
}

export interface SendLetterPage {
  page: number;
  header?: string;
  body?: string;
  footer?: string;
}

export interface SendLettersContent extends TriggerEngineRequestContent {
  deliveryType: string;
  productType: string;
  duplex: boolean;
  mono: boolean;
  pages: SendLetterPage[];
  templateName: string;
  assets?: LetterTemplateAsset[];
}

export interface AddPifuContent extends TriggerEngineRequestContent {
        name: string;
        context: string;
}

export interface AutoCommunicationContent extends TriggerEngineRequestContent {
    emailIfRegistered?: SendEmailContent;
    emailIfUnregistered?: SendEmailContent;
    sms?: SendSmsContent;
    letter?: SendLettersContent;
}

export interface SendResourceCollectionContent extends TriggerEngineRequestContent {
    resourceCategoryUuids: string[];
    message?: {
        title: string;
        content: string;
        type: string;
    };
}

export interface AssignPathwayEventContent extends TriggerEngineRequestContent {
    pathwayEvent: string;
}
